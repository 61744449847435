<template>
	<div class="container">
		<common-head title="量化详情" :is-return="true"></common-head>
		<div class="content">
			<div class="user_msg">
				<div class="content">
					<div class="list0">
						<div class="con_left">基本信息</div>
						<div class="con_right right-jingxin" v-if="user_info.state == 1">进行中</div>
						<div class="con_right right-shenhe" v-else>未开始</div>
					</div>
					<div class="list">
						<div class="con_left">显示名称:</div>
						<div class="con_right">{{user_info.deposit_name}}</div>
					</div>
					<div class="list">
						<div class="con_left">选择交易所:</div>
						<div class="con_right">{{user_info.exchange}}</div>
					</div>
					<div class="list">
						<div class="con_left">交易方式:</div>
						<div class="con_right">{{user_info.deposit_type}}</div>
					</div>
					<div class="list">
						<div class="con_left">分成比例:</div>
						<div class="con_right">{{user_info.brokerage_ratio*100}}%</div>
					</div>
					<div class="list">
						<div class="con_left">账户总资产:</div>
						<div class="con_right">
							<div class="left">{{user_info.me_set_fund}} USDT</div>
							<div class="right right-shenhe"  v-if="user_info.state == 2">审核中</div>
						</div>
					</div>
					<div class="button" v-if="user_info.state == 2" @click="che(2)">撤回信息审核</div>
                    <div class="button" @click="routerTo2(user_info)">修改信息</div>
				</div>
			</div>
			<div class="user_msg">
				<div class="content">
					<div class="list3"><div class="con_left">我的托管</div></div>
					<div class="list4">
						<div class="con_left1">累计佣金收入：</div>
						<div class="con_right1">{{user_info.brokerage_total}} USDT</div>
					</div>
					<div class="list5">
						<div class="dq" :class="{ active: is_dq == 1 }" @click="qiehuan(1)">当前托管</div>
						<div class="ls" :class="{ active: is_dq == 2 }" @click="qiehuan(2)">历史托管</div>
					</div>
					<van-list
					        v-model="billLoading"
					        :finished="billFinished"
					        :finished-text="$t('cashFlow.NoMore')"
					        :loading-text="$t('common.loading')"
					        @load="onLoadcashFlow"
					>
					  <van-row
					    class="list6"
					    v-for="(item, index) in billList"
					    :key="index"
					  >
					    <van-col span="6.5">
							 <div class="left_user">
								<div class="img"><img :src="item.avatar" alt="" /></div>
								<div class="name">{{item.student_name}}</div>
							 </div>
					    </van-col>
						<van-col>
							 <div class="msg-center">
							 	<div class="msg-item">
							 		<div class="msg-left">托管资金</div>
							 		<div class="msg-right">{{item.trusteeship_amount}}USDT</div>
							 	</div>
							 	<div class="msg-item">
							 		<div class="msg-left">托管盈利</div>
							 		<div class="msg-right">{{item.trusteeship_profit}}USDT</div>
							 	</div>
							 	<div class="msg-item">
							 		<div class="msg-left">佣金收益</div>
							 		<div class="msg-right">{{item.brokerage}}USDT</div>
							 	</div>
							 </div>
						</van-col>
						<van-col>
							 <div class="msg-last">
							 	<div class="msg-right-top">{{item.growth_rate}}</div>
							 	<div class="msg-right-bottom">托管收益率</div>
							 </div>
						</van-col>
					  </van-row>
					</van-list>
				</div>
			</div>
		</div>
		<div class="bottom" v-if="user_info.state == 1">
			<div class="button" @click="stop(0)">停止量化</div>
		</div>
        <div class="bottom" v-if="user_info.state == 0">
        	<div class="button" @click="start(2)">开启量化</div>
        </div>
		<van-popup v-model="show" round>
            <div class="tan" v-if="show1 == 2">
            	<div class="tan_no_txt1">确定要开启量化吗？</div>
            	<div class="tan_no_button">
            		<div class="yes" @click="yes0()">确认</div>
            		<div class="no" @click="no()">暂时不要</div>
            	</div>
            </div>
			<div class="tan" v-if="show1 == 0">
				<div class="tan_no_txt1">确定要停止量化吗？</div>
				<div class="tan_no_txt2">停止之后将不能继续带单</div>
				<div class="tan_no_button">
					<div class="yes" @click="yes()">确认</div>
					<div class="no" @click="no()">暂时不要</div>
				</div>
			</div>
			<div class="tan" v-if="show1 == 1">
				<div class="tan_no_txt1">确定要撤回申请吗？</div>
				<div class="tan_no_button">
					<div class="yes" @click="yes1()">确认</div>
					<div class="no" @click="no()">暂时不要</div>
				</div>
			</div>
		</van-popup>
	</div>
</template>

<script>
// import TitleTwo from "../../components/titleTwo";
import commonHead from '../../components/commonHead';
export default {
	components: { commonHead },
	data() {
		return {
			show: false,
			is_dq: 1,
			show1: '',
			user_info: {},
			config_id: this.$route.query.config_id,
			billLoading: false,
			billFinished: false,
			billData: [],
			billList: [],
			billPage: 1,
		};
	},
	created() {
		console.log( this.$route.query.config_id);
		this.getTrusteeshipInfo()
	},
	methods: {
		stop() {
            this.show1 = 0;
			this.show = !this.show;
		},
        yes0() {
            this.startTrusteeshipConfig()
        },
		yes() {
			this.stopTrusteeshipConfig()
		},
		yes1() {
			this.cancelApplyTrusteeshipTeacher()
		},
		no() {
			this.show = !this.show;
		},
		che(e) {
            this.show1 = 1;
			this.show = !this.show;
		},
        start(e) {
            this.show1 = 2;
        	this.show = !this.show;
        },
		qiehuan(e) {
			this.is_dq = e;
			this.billData = [];
			this.billList = [];
			this.billPage = 1;
            this.billFinished = false;
            this.billLoading = true;
			this.onLoadcashFlow();
		},
		routerTo(e) {
			this.$router.push(e);
		},
		routerTo2(e) {
			this.$router.push({
				path: './become_teacher',
				query: {
					msg: '修改量化',
					info: e
				}
			});
		},
		routerTo1(e) {
			this.$router.push({
				path: './lianghua',
				query: {
					// bot_id: item.bot_id
				}
			});
		},
		//量化详情页 - 带单老师详情
		getTrusteeshipInfo() {
		    this.$post2("Deposit/Api/Index/getTrusteeshipInfo", {
				config_id: this.config_id
			})
		        .then((res) => {
		            // console.log(res);
					this.user_info = res
		        })
		        .catch((e) => {
		            this.$toast.fail(e);
		        });
		},
		//量化详情页 - 我的托管
		getTrusteeshipStudents() {
		    this.$post2("Deposit/Api/Index/getTrusteeshipStudents", {
					config_id: this.config_id,
				 page: this.billPage++,
				 limit: 10,
				 type: this.is_dq
			})
		        .then((res) => {
		            // console.log(res);
					this.billData = res.list
		        })
		},
		onLoadcashFlow() {
		  this.getTrusteeshipStudents();
		  // 异步充值记录
		  setTimeout(() => {
		    for (let i = 0; i < this.billData.length; i++) {
		      this.billList.push(this.billData[i]);
		    }
		    // console.log(this.shouyiList);
		    // 加载状态结束
		    this.billLoading = false;
		    // 数据全部加载完成
		
		    console.log(this.billData);
		    if (this.billData.length < 1) {
		      this.billFinished = true;
		    }
		  }, 1400);
		},
		//用户撤回申请
		cancelApplyTrusteeshipTeacher() {
		    this.$post2("Deposit/Api/Index/cancelApplyTrusteeshipTeacher", {
				id: this.config_id
			})
		        .then((res) => {
		            // console.log(res);
					this.$toast('撤回成功');
                    this.show = !this.show;
					this.$router.push('/im_teacher')
		        })
		        .catch((e) => {
		            // this.$toast.fail(e);
		        });
		},
		//老师停止某个托管
		stopTrusteeshipConfig() {
		    this.$post2("Deposit/Api/Index/stopTrusteeshipConfig", {
				config_id: this.config_id
			})
		        .then((res) => {
		            // console.log(res);
					this.$toast('停止成功');
                    this.show = !this.show;
					this.$router.push('/im_teacher')
		        })
		        .catch((e) => {
		            this.$toast.fail(e);
		        });
		},
        //老师开启某个托管
        startTrusteeshipConfig() {
            this.$post2("Deposit/Api/Index/startTrusteeshipConfig", {
        		config_id: this.user_info.id
        	})
                .then((res) => {
                    // console.log(res);
        			this.$toast('开启成功');
                    this.show = !this.show;
                    this.$router.push('/im_teacher')
                })
                .catch((e) => {
                    this.$toast.fail(e);
                });
        },
	}
};
</script>

<style scoped lang="less">
.container {
	background-color: #f8f8f8;
	height: calc(100vh + 110px);
}
.user_msg {
	background-color: #ffffff;
	margin: 10px;
	border-radius: 8px;
	box-shadow: 0 3px 9px rgba(0, 0, 0, 0.1);
	font-family: PingFang SC;
	.content-top {
		margin: 0 0.4rem;
		.all_cout {
			display: flex;
			padding: 0.24rem 0;
			.con_left {
				color: #5899f6;
				font-size: 0.3rem;
				font-weight: 500;
			}
			.con_right {
				color: #5899f6;
				font-size: 0.3rem;
				font-weight: 500;
				margin-left: 0.28rem;
			}
		}
	}
	.content {
		margin: 0 0.4rem;
		padding: 0.12rem 0 0.38rem 0;
		.list {
			display: flex;
			align-items: center;
			padding: 0.08rem 0;
			.con_left {
				width: 30%;
				color: #333333;
				font-size: 0.3rem;
				font-weight: 500;
				text-align: left;
			}
			.con_left1 {
				color: #5899f6;
				font-size: 0.24rem;
				font-weight: 500;
				text-align: left;
			}
			.con_right {
				width: 70%;
				color: #666666;
				font-size: 0.28rem;
				margin-left: 0.28rem;
			}
			.con_right1 {
				color: #5899f6;
				font-size: 0.24rem;
			}
			.right-jingxin {
				color: #13ad8f;
			}
			.right-shenhe {
				color: #d98181;
			}
		}
		.list0 {
			display: flex;
			justify-content: space-between;
			border-bottom: 0.01rem solid #999999;
			align-items: center;
			padding: 0.1rem 0;
			.con_left {
				color: #333333;
				font-size: 0.3rem;
				font-weight: bold;
				text-align: left;
			}
			.con_right {
				color: #666666;
				font-size: 0.28rem;
				margin-left: 0.28rem;
			}
			.right-jingxin {
				color: #13ad8f;
			}
			.right-shenhe {
				color: #d98181;
			}
		}
		.list3 {
			padding: 0.1rem 0;
			.con_left {
				color: #333333;
				font-size: 0.3rem;
				font-weight: bold;
				text-align: left;
			}
		}
		.list4 {
			display: flex;
			align-items: center;
			padding-bottom: 0.16rem;
			border-bottom: 0.01rem solid #8b9fad;
			.con_left1 {
				color: #5899f6;
				font-size: 0.24rem;
				font-weight: 500;
				text-align: left;
			}
			.con_right1 {
				color: #5899f6;
				font-size: 0.24rem;
			}
		}
		.list5 {
			display: flex;
			align-items: center;
			padding: 0.16rem 0;
			.dq {
				color: #8b9fad;
				font-size: 0.28rem;
				font-weight: 500;
				text-align: center;
			}
			.ls {
				margin-left: 0.46rem;
				color: #8b9fad;
				font-size: 0.28rem;
				font-weight: 500;
				text-align: center;
			}
			.active {
				color: #5899f6;
				font-size: 0.3rem;
			}
		}
		.list6 {
			margin-top: 0.46rem;
			display: flex;
			justify-content: space-between;
			padding-bottom: 0.3rem;
			border-bottom: 0.01rem solid #8b9fad;
			.left_user {
				display: flex;
				flex-direction: column;
				align-items: center;
				.img {
					width: 0.8rem;
					height: 0.8rem;
					img {
						border-radius: 0.5rem;
						width: 100%;
						height: 100%;
					}
				}
				.name {
					margin-top: 0.26rem;
					color: #333333;
					font-weight: 500;
					font-size: 0.28rem;
				}
			}
			.msg-center {
				// margin-left: -0.3rem;
				.msg-item {
					margin-top: 0.08rem;
					display: flex;
					align-items: center;
					justify-content: space-between;
					.mag-left {
						color: #333333;
						font-size: 0.3rem;
						font-weight: 500;
						text-align: left;
					}
					.msg-right {
						margin-left: 0.08rem;
						color: #666666;
						font-size: 0.28rem;
					}
				}
				.msg-item:first-child {
					margin-top: 0;
				}
				.msg-item:last-child {
					margin-top: 0.18rem;
				}
			}
			.msg-last {
				margin-top: 0.7rem;
				.msg-right-top {
					text-align: right;
					font-size: 0.3rem;
					font-weight: bold;
					color: #d98181;
				}
				.msg-right-bottom {
					text-align: center;
					font-size: 0.24rem;
					font-weight: bold;
					color: #d98181;
				}
			}
		}
		.list6:last-child {
			border-bottom: none;
		}
		.button {
			margin: 0 auto;
			margin-top: 0.3rem;
			width: 6rem;
			height: 0.76rem;
			background-color: #5899f6;
			color: #ffffff;
			line-height: 0.76rem;
			border-radius: 0.16rem;
			text-align: center;
			font-size: 0.28rem;
		}
	}
}
.bottom {
	position: fixed;
	left: 0;
	bottom: 0;
	background-color: #ffffff;
	padding: 0;
	.button {
		margin: 0.14rem 0.3rem 0 0.28rem;
		padding: 0.22rem 2.82rem;
		background-color: #5899f6;
		color: #ffffff;
		font-size: 0.32rem;
		text-align: center;
		border-radius: 0.12rem;
		font-weight: 500;
	}
}
.tan {
	width: 5.6rem;
	height: 3.6rem;
	background-image: url(../../assets/imgs/tan_bg.png);
	background-repeat: no-repeat;
	background-size: 100% 3.6rem;
	.tan_no_txt1 {
		padding: 1.4rem 0.82rem 0 0.72rem;
		color: #333333;
		font-size: 0.28rem;
		text-align: center;
		font-weight: 500;
		color: #333333;
	}
	.tan_no_txt2 {
		margin-top: 0.05rem;
		color: #333333;
		font-size: 0.24rem;
		transform: scale(0.75);
		font-weight: 500;
		color: #666666;
		text-align: center;
		.right {
			color: #d98181;
		}
	}
	.tan_no_button {
		margin: 0 1.02rem;
		margin-top: 0.5rem;
		display: flex;
		justify-content: space-between;
		.yes {
			color: #5899f6;
			padding: 0.08rem 0.32rem;
			border: 1px solid #5899f6;
			border-radius: 0.16rem;
			background-color: #ffffff;
		}
		.no {
			color: #ffffff;
			padding: 0.1rem 0.22rem 0.08rem 0.24rem;
			border-radius: 0.16rem;
			background-color: #5899f6;
		}
	}
}
</style>
